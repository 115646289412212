<template>
    <v-container fluid class="mb-4">
        <v-row>
          {{ items[items.length - 1].text }}
        </v-row>
        <v-row>
            <v-col cols="11">
                <v-row>
                    <v-icon>fas fa-home</v-icon>
                    <v-breadcrumbs
                        class="px-3"
                        :items="items"
                    ></v-breadcrumbs>
                </v-row>
            </v-col>
            <v-col cols="1">
                <span @click="disconnect" class="font-weight-bold" :style="{ cursor: 'pointer' }">Deconnexion</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import { mapActions } from "vuex";

export default {
    name: "PageBanner",
    props: {
        links: {
            type: String,
            required: true,
        },
    },


    computed: {
        items: function() {
            const items = [];

            const newItems = this.links.split("/");

            for(const index in newItems) {
                items.push({ text: newItems[index] });
            }

            return items;
        }
    },

    methods: {
        ...mapActions("settings", ["disconnect"]),
    }
};
</script>

<style scoped>
</style>
