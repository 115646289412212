<template>
    <div>
        <v-menu offset-y max-height="500px" v-if="Array.isArray(type)">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" :loading="isDownloading">
                    <v-icon>fas fa-download</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="(item, index) in type"
                    :key="index"
                    @click="generateDocument(item)"
                >
                    <v-list-item-title>{{ equiv[item] }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-btn icon v-else-if="type" @click.stop="generateDocument(type)" :loading="isDownloading">
            <v-icon>fas fa-download</v-icon>
        </v-btn>

    </div>
</template>

<script>
import { saveAs } from "file-saver";
import { mapState, mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
    name: "DownloadButton",

    props: {
        downloadType: {
            type: String,
        },
        objectId: {
            type: String,
        }
    },

    data: () => ({
        equiv: {
            script: "Script",
            referential: "Référentiel"
        },

        isDownloading: false,
    }),

    computed: {
        ...mapState("formations", ["currentFormation", "educationalObjectives", "operationalObjectives", "specificObjectives"]),
        ...mapState("synopsis", ["currentSynopsis"]),

        ...mapState("global", ["downloadList"]),
        ...mapState("settings", ["user"]),

        ...mapGetters("formations", [
            "getFormationById",
            "getEducationalObjectiveById",
            "getOperationalObjectiveById",
            "getSpecificObjectiveById,",
        ]),

        type: function() {
            return this.$route.meta.download || this.downloadType;
        },

        items: function() {
            const elements = this.type.split(";");
            return elements.map((el) => this.downloadList[el]);
        },
    },

    methods: {
        ...mapActions("global", ["addAlert"]),


        generateDocument: async function(type) {
            try {
                let result;
                this.isDownloading = true;

                if(type === "synopsis") {
                    result = await axios.get(`/synopsis/${this.currentSynopsis}/export/`, {
                        responseType: "blob",
                    });
                } else {
                    result = await axios.get(`/formations/${this.currentFormation}/export/${type}${this.objectId ? "/" + this.objectId : ""}`, {
                        responseType: "blob",
                    });
                    
                }

                saveAs(result.data, result.headers["x-suggested-filename"]);

                this.isDownloading = false;
               
            } catch (e) {
                this.addAlert({
                    type: "error",
                    title: "Une erreur est survenue",
                    message: "Merci de réessayer plus tard.",
                    duration: 5000,
                });

                this.isDownloading = false;
                return;
            }
        },
    },
};
</script>

<style scoped></style>
